/* eslint-disable import/prefer-default-export */
import ivan from "../assets/team/ivan.png";
import alejandro from "../assets/team/alejandro.png";
import arsenii from "../assets/team/arsenii.png";
import luis from "../assets/team/luis.png";
// import diego from "../assets/team/diego.png";
import kirill from "../assets/team/kirill.png";
import safeDeployment1 from "../assets/services/safeDeployment1.png";
import safeDeployment2 from "../assets/services/safeDeployment2.png";

export const solutionDetailsMap = {
  "ccip-integration": {
    metaTitle: "CCIP Integration | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Seamlessly integrate Chainlink's CCIP with Protofire. Grow your user base, enhance token usage, and enable secure cross-chain transactions across 16+ networks.",
      },
      {
        name: "keywords",
        content:
          "CCIP Integration, Cross-Chain Interoperability, Blockchain Protocol Expansion, Chainlink Integration Services, Multi-Chain DeFi Solutions",
      },
    ],
    title: "CCIP Integration",
    description: [
      "We integrate protocols with Chainlink’s Cross-Chain Interoperability Protocol to help them grow their user base and expand token usage.",
    ],
    customers: ["SuperRare", "Kryptomon"],
    node: "SNP/Non-EVM",
    additionalContent: [
      {
        title: "Description",
        description: [
          "Protofire as the CCIP Chainlink Integration partner facilitate a seamlessly CCIP deployment into protocols to enable interoperability between blockchains and let the customer team focus on architecture and integration with their existing stack.",
          "The networks available for CCIP",
        ],
        items: [
          "Arbitrum",
          "Avalanche",
          "Base",
          "Blast",
          "BNB Chain",
          "Celo",
          "Ethereum",
          "Gnosis Chain",
          "Kroma",
          "Linea",
          "Metis",
          "Mode",
          "Optimism",
          "Polygon",
          "Scroll",
          "Wemix",
          "ZKsync",
        ],
      },
      {
        title: "Benefits",
        items: [
          "Seamless interaction with Chainlink services",
          "Simple integration of Chainlink services into various projects",
          "Smooth dApp testing across Chainlink services locally using Hardhat or Foundry",
          "Simplified development of advanced dApps",
        ],
      },
      {
        title: "Use cases",
        items: [
          {
            title: "Token Bridge & Migrations",
            subItems: [
              "CCIP can be deployed to lower user fees enrouting transactions or by entirely migrating through high-output and cost-efficient chains.",
            ],
          },
          {
            title: "Token collateralization across chains",
            subItems: [
              "Tokens on one blockchain can be used as collateral on another, enhancing liquidity and capital efficiency.",
            ],
          },
          {
            title: "Cross-chain NFT minting and trading",
            subItems: [
              "NFTs can be minted on one chain and traded or used in games on another, increasing accessibility and market reach.",
            ],
          },
          {
            title: "Cross-chain lending and borrowing",
            subItems: [
              "Enables borrowing of tokens on one blockchain against collateral on another, opening up diverse lending markets.",
            ],
          },
          {
            title: "Token utilization in gaming ecosystems",
            subItems: [
              "In-game tokens can be used across blockchain platforms, increasing their utility in various gaming environments.",
            ],
          },
          {
            title: "Yield optimization across chains",
            subItems: [
              "DeFi protocols can optimize yields by accessing different liquidity pools and strategies across blockchains.",
            ],
          },
          {
            title: "In-game asset tokenization and cross-chain transfers",
            subItems: [
              "Assets in games can be tokenized and transferred across chains, enhancing their value and utility.",
            ],
          },
          {
            title: "Cross-chain liquidity pools for swapping and trading",
            subItems: [
              "Facilitates the creation of liquidity pools that span multiple blockchains, improving market depth and reducing slippage.",
            ],
          },
          {
            title: "Interoperability for esports and competitive gaming",
            subItems: [
              "Players on different blockchains can interact and compete, broadening the scope of competitive gaming.",
            ],
          },
          {
            title: "Governance across chains",
            subItems: [
              "Token holders can participate in governance decisions across multiple blockchains, decentralizing control and decision-making.",
            ],
          },
          {
            title:
              "Seamless asset transfer for collaborative gaming experiences",
            subItems: [
              "Assets and tokens can be easily transferred between games on different blockchains, enriching the gaming experience.",
            ],
          },
          {
            title:
              "Cross-chain token transfers (Burn and Mint, Lock and Mint, Lock and Unlock)",
            subItems: [
              "Enables seamless token transfers between blockchains, enhancing liquidity and accessibility for both sectors.",
            ],
          },
          {
            title: "Cross-chain staking mechanisms",
            subItems: [
              "Allows staking of assets on one chain while earning rewards on another, expanding opportunities for yield generation.",
            ],
          },
          {
            title: "Cross-chain data sharing and messaging",
            subItems: [
              "Facilitates the sharing of data and messages across blockchains, enabling complex and interconnected applications between DeFi and GameFi.",
            ],
          },
        ],
      },
    ],
    contact: {
      image: alejandro,
      name: "Alejandro Losa",
      lnUrl: "https://www.linkedin.com/in/alejandrolosa/ ",
      calendlyCallPostfix: "alejandro-protofire",
      text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //--------------------------------------------------------------------

  "safe-deployment": {
    metaTitle: "Safe Wallet Deployment Services | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Expert Safe Wallet deployment and maintenance for 40+ blockchains. Official Safe partner offering custom integrations and ecosystem support.",
      },
      {
        name: "keywords",
        content:
          "Safe Wallet deployment, Blockchain integration, Multisig wallet, Smart contract security, DApp integration",
      },
    ],
    title: "Safe Deployment",
    description: [
      "Protofire is a leading blockchain collective for a fast and secure Safe Wallet deployment on networks. Protofire is an official infrastructural partner of Safe (previously Gnosis Safe) and Safe Guardian, widely recognized within the Safe ecosystem.",
    ],
    customers: [
      "Moonbeam",
      "Evmos",
      "Velas",
      "Cronos / Cronos zkEVM",
      "Astar / Astar zkEVM",
      "IoTeX",
      "ThunderCore",
      "Tenet",
      "Telos",
      "Oasis",
      "Sei",
      "Kava",
      "Morph",
      "Saakuru",
      "Etherlink",
      "Taiko",
      "Harmony One",
      "Neon EVM",
      "Linea",
      "Rootstock",
      "Mantle",
      "Cascadia",
      "zkScroll",
      "Holesky",
      "Zetachain",
      "zkSync",
      "WEMIX",
      "Kroma",
      "BOB",
      "Zilliqa",
      "Immutable",
      "Superchain",
      "Blast",
      "Vana",
      "Mode",
      "Zora",
      "Fraxtal",
      "PGN",
      "Redstone",
      "Lisk",
      "Cyber",
      "Mint",
      "Snaxchain",
      "OP Sepolia",
      "XAI",
      "Boba",
      "Autonomys",
      "Shape",
      "Sophon",
      "AlephZero",
      "Gelato",
      "Reya",
      "Connext",
      "Opencampus",
      "Tangible",
      "Berachain",
    ],
    node: "Web3US",
    // learnMore: {
    //   text: "Safe Multisig on ecosystems",
    //   link: "https://docs.google.com/presentation/d/1dVdR6oRc87uC9ra_nBB0P5QiFOAERxAH3S-uWWzB7iM/edit#slide=id.g1e3f8d355c0_1_0",
    // },
    additionalContent: [
      {
        title: "Description",
        description: [
          "Protofire has successfully deployed Safe to >40 blockchains, including Optimism Superchain, Blast, Cronos, Moonbeam, Harmony, Astar, Oasis, Mantle, zkSync, zkScroll, Linea, Holesky, and others becoming a proven expert in the Safe ecosystem.",
          "Protofire is the official partner of Safe Team, providing fast, reliable, and secure Safe Wallet deployment, support, and maintenance. The Protofire team collaborated with Safe developers on the design and implementation of Safe functionality from 2017 to 2020, so we have extensive knowledge of the Safe codebase.",
          "The Protofire team has been named the Safe Guardian 🔰 for stewarding the SAFE ecosystem and protocol and aligning Safe’s vision to drive its adoption of smart contract accounts.",
        ],
      },
      {
        title: "Services",
        description: [
          "<span class='text-white100'>Turn-key deployment/Maintenance of custom Safe forks</span>",
          "We continuously help Safe improve and upscale its trusted platform for digital asset management, accelerating its transformation into a dynamic ecosystem. Having deployed Safe on over 40 mainnets and testnets, we actively engage with network ecosystems and communities for prompt feedback and enhancement suggestions. Both deployment and maintenance are performed under the supervision of the Safe team to ensure the integrity of the network and its current and upcoming features.",
          `<img src=${safeDeployment1} alt="safeDeployment1" class="rounded-xl">`,
          "<span class='text-white100'>Custom integrations of Safe Apps</span>",
          "Safe Apps provides direct access to DApps from Safe UI. Some are network-agnostic and can be added to the Safe on any network, but we also implement custom Safe Apps, increasing the TVL of DApps and the network in general. For example, ArthSwap is a Safe App listed in Astar Safe, allowing direct trading on ArthSwap DEX from the Safe UI.",
          `<img src=${safeDeployment2} alt="safeDeployment2" class="rounded-xl">`,
        ],
      },
      {
        title: "About Safe",
        items: [
          "The most secure Smart-Contract based multisig wallet available on EVM networks",
          "Designed for individuals, DAOs, and teams of any size",
          "Native ETH, ERC 20, and ERC 721/1155 (NFTs) support",
          "Customize with Modules, Guards, and Safe Apps.",
        ],
      },
    ],
    technologyStack: {
      items: [
        {
          title: "Programming Languages",
          items: ["TypeScript", "Python", "Solidity"],
        },
        {
          title: "Frameworks and Libraries",
          items: ["Node.js", "React", "Styled Components", "Ethers", "Django"],
        },
        {
          title: "Infrastructure",
          items: [
            "AWS",
            "CloudFormation",
            "Terraform",
            "Ansible",
            "Redis / ElastiCache",
            "RDS / PostgreSQL",
          ],
        },
        {
          title: "Platforms",
          items: [
            "L1 blockchains",
            "L2 blockchains",
            "Zk-rollups",
            "Optimistic rollups",
            "App-chains",
          ],
        },
        {
          title: "Tools",
          items: ["Storybook", "Hardhat"],
        },
      ],
    },
    // codeData: {
    //   items: [
    //     {
    //       title: "Velas Safe",
    //       link: "https://github.com/protofire/velas-transaction-service",
    //     },
    //   ],
    // },
    mediaData: {
      items: [
        {
          title: "Harmony Multisig Wallet",
          link: "https://medium.com/harmony-one/gnosis-safe-multisig-now-available-on-harmony-56f83b7222c5",
        },
        {
          title: "Astar Safe",
          link: "https://medium.com/astar-network/protofire-is-building-a-safe-astar-324c73333a06",
        },
        {
          title: "Cronos Safe",
          link: "https://docs.cronos.org/for-dapp-developers/dev-tools-and-integrations/cronos-safe",
        },
        {
          title: "IoTeX Safe",
          link: "https://developers.iotex.io/posts/gnosis-safe-integration",
        },
        {
          title: "ThunderCore Safe",
          link: "https://coinmarketcap.com/community/articles/6451c49c0d1ac45ba873d3ab/",
        },
        {
          title: "Oasis Safe",
          link: "https://medium.com/protofire-blog/oasis-safe-on-oasis-sapphire-286f3bcf2d73",
        },
        {
          title: "Telos Safe",
          link: "https://medium.com/telos-foundation/introducing-telos-safe-2683a5d07448",
        },
        {
          title: "Moonbeam Safe",
          link: "https://medium.com/protofire-blog/protofire-secures-assets-and-transactions-in-moonbeam-with-a-fork-of-gnosis-safe-26b185a30cd1",
        },
        {
          title: "Evmos Safe",
          link: "https://medium.com/evmos/protofire-deploys-a-gnosis-safe-fork-to-evmos-cc377bfdf572",
        },
        {
          title: "Tenet Safe",
          link: "https://safe.tenet.org/",
        },
        {
          title: "Neon EVM Safe",
          link: "https://p2p.org/economy/everything-you-need-to-know-about-neon-evm/",
        },
        {
          title: "Linea Safe",
          link: "https://linea.mirror.xyz/iiLnw4AS643gNngn79zugoh-qCAqR5oen9iEzxEKqzA",
        },
        {
          title: "Rootstock Safe",
          link: "https://blog.rootstock.io/noticia/safe-multisig-wallet-now-available-on-rootstock/",
        },
        {
          title: "Mantle Safe",
          link: "https://twitter.com/0xMantle/status/1717525533758779807?t=ss6zd93HjXgNIfZinhIWHA&s=19",
        },
        {
          title: "Cascadia Safe",
          link: "https://medium.com/protofire-blog/protofire-deploys-safe-on-cascadia-testnet-ddd908090fbc",
        },
        {
          title: "zkScroll Safe",
          link: "https://twitter.com/BuildWithScroll/status/1717224595651203546",
        },
        {
          title: "Holesky Safe",
          link: "https://research.lido.fi/t/safe-ex-gnosis-safe-deployment-on-holesky/5726/7",
        },
        {
          title: "Deploying Safe, Driving User Growth & Developer Adoption",
          link: "https://medium.com/subspace-network/autonomys-x-protofire-driving-user-growth-developer-adoption-999eaf3c9b20",
        },
      ],
    },
    contact: {
      image: ivan,
      name: "Ivan Bandaryk",
      lnUrl: "https://www.linkedin.com/in/ivan-bandaryk/?originalSubdomain=pl",
      calendlyCallPostfix: "ivan-protofire",
    },
  },

  //--------------------------------------------------------------------

  "blockscout-development": {
    metaTitle:
      "Blockscout Development: Custom Integration & Optimization | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Enhance your blockchain ecosystem with custom Blockscout development, upgrades, and support. Optimize costs and improve UX for EVM, UTXO, and multi-shard networks.",
      },
      {
        name: "keywords",
        content:
          "Blockscout development, Blockchain optimization, Custom network integration, EVM UTXO support, Blockchain infrastructure",
      },
    ],

    title: "Blockscout Development",
    description: [
      "We provide custom Blockscout development, upgrades, hosting, and support to deliver an exceptional user experience and enhanced functionality for your ecosystem.",
    ],
    customers: ["Harmony", "Quai"],
    node: "Web3US",
    // learnMore: {
    //   text: "Blockscout Development Slide deck",
    //   link: "https://docs.google.com/presentation/d/1dVdR6oRc87uC9ra_nBB0P5QiFOAERxAH3S-uWWzB7iM/edit#slide=id.g1e3f8d355c0_1_0",
    // },
    additionalContent: [
      {
        title: "Description",
        description: [
          "We seamlessly integrate Blockscout with networks to improve their user experience and optimize cost efficiency.",
        ],
      },
      {
        title: "Services",
        description: [
          "We integrate Blockscout into networks with non-EVM functionality that combines EVM and UTXO models, multisharding, etc.<br/><a target='_blank' href='https://github.com/protofire/quai-blockscout' class='underline underline-offset-1 text-white100'>Quai</a> is a network with advanced functionality, including UTXO model usage. We improved the overall productivity of the network with the Blockscout deployment.",
          "We help to optimize costs by hosting the entire solution on bare metal. This is an optimal solution for networks with many users and high usage.<br/><a target='_blank' href='https://github.com/harmony-one/protofire-safe-transaction-service' class='underline underline-offset-1 text-white100'>Harmony</a> is an example of multi-sharding and heavy usage, so we enhanced their UI and optimized infrastructure costs.",
        ],
      },
      {
        title: "Comprehensive support service includes",
        items: [
          "Infrastructure Monitoring",
          "Maintenance and Updates",
          "Blockscout code updates",
          "Bug Fixing",
          "Development of new features and functionality",
        ],
      },
    ],
    mediaData: {
      items: [
        {
          title: "Harmony One Blockscout Explorer",
          link: "https://bs-harmony-testnet.protofire.io/",
        },
        {
          title: "Quai Proposal",
          link: "https://docs.google.com/presentation/d/1dVdR6oRc87uC9ra_nBB0P5QiFOAERxAH3S-uWWzB7iM/edit#slide=id.g2a5860b2dfe_1_2 ",
        },
      ],
    },
    contact: {
      image: ivan,
      name: "Ivan Bandaryk",
      lnUrl: "https://www.linkedin.com/in/ivan-bandaryk/?originalSubdomain=pl",
      calendlyCallPostfix: "ivan-protofire",
    },
  },

  //--------------------------------------------------------------------

  "uniswap-v3-integration": {
    metaTitle:
      "Expert Uniswap v3 Integration Services for DeFi Ecosystems | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Seamlessly integrate Uniswap v3 into your DeFi ecosystem. Our skilled team handles smart contracts, subgraphs, frontend, and analytics deployment.",
      },
      {
        name: "keywords",
        content:
          "Uniswap v3 integration, DeFi ecosystem expansion, Smart contract deployment, Blockchain development services, Decentralized exchange integration",
      },
    ],
    title: "Uniswap v3 Integration",
    description: [
      "We integrate Uniswap v3 with DeFi ecosystems to help them expand the outreach and diversify the ecosystem accessibility.",
    ],
    customers: ["Harmony", "RedStone", "Zora Swap", "Abstract"],
    node: "Web3US",
    // learnMore: {
    //   text: "UNI V3 by Protofire (LINK IS NEEDED!!!!!!)",
    //   link: "",
    // },
    additionalContent: [
      {
        title: "Description",
        description: [
          "Uniswap v2 and v3 integration by Protofire includes all of the following major steps according to Uniswap’s official guide.",
          "We provide a highly skilled team to cover all necessary technologies including AWS, Solidity, Typescript, Rust, Node.js, Django, and React, and help prepare Github PRs for the Uni v3 integration.",
        ],
      },
      {
        title: "Services",
        items: [
          "Audited Smart Contract deployment & verification",
          "Uniswap subgraphs deployment",
          "Uniswap packages configuration",
          "Compliant Swap Frontend configuration and deployment",
          "Analytics Frontend configuration and deployment",
          "Graph-node deployment & support (Optional)",
          "Token-list preparation (Optional)",
        ],
      },
    ],
    mediaData: {
      items: [
        {
          title: "Swap.Country",
          link: "https://swap.country/",
        },
        {
          title: "RedSwap",
          link: "https://redswap.io/",
        },
        {
          title: "Zora Swap ",
          link: "http://swap.zora.energy",
        },
        {
          title: "Zora and Redswap forks",
          link: "https://gov.uniswap.org/t/official-uniswap-v3-deployments-list/24323/3",
        },
      ],
    },
    contact: {
      image: ivan,
      name: "Ivan Bandaryk",
      lnUrl: "https://www.linkedin.com/in/ivan-bandaryk/?originalSubdomain=pl",
      calendlyCallPostfix: "ivan-protofire",
    },
  },

  //--------------------------------------------------------------------

  "the-graph": {
    metaTitle:
      "The Graph Protocol Integration: Efficient Blockchain Data Indexing | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Accelerate dApp development with The Graph protocol integration. Efficient blockchain data indexing, subgraph deployment, and maintenance for networks.",
      },
      {
        name: "keywords",
        content:
          "The Graph protocol integration, Blockchain data indexing, Subgraph deployment, dApp development acceleration, Decentralized data services",
      },
    ],

    title: "The Graph",
    description: [
      "We bring The Graph protocol services, the #1 data indexing standard, and its subgraphs to networks enabling dApps to efficiently query blockchain data while reducing the need for in-house data indexing solutions and infrastructure. ",
    ],
    customers: ["Astar Network", "Kakarot Network"],
    node: "DevOps",
    learnMore: {
      text: "The Graph Deployment",
      link: "https://docs.google.com/presentation/d/1ODxVeDlCIIbK1U96ppEs-7jP_PM6jlN0CyvXpHDWA7U/edit#slide=id.g137cde23961_0_65",
    },
    additionalContent: [
      {
        title: "Description",
        description: [
          "Protofire is a long-time partner of The Graph and is one of the biggest indexers in The Graph ecosystem.",
          "Protofire deploys The Graph's subgraphs and GraphQL API to accelerate the development and integration of dApps in the target network, facilitating quicker go-to-market for new applications and features.",
          "Graph's decentralized nature ensures that data is accessible to everyone, fostering a transparent and inclusive ecosystem where developers can contribute and benefit. Real-time data updates enable dApps to offer up-to-date information and improve user experience and decision-making processes.",
        ],
      },
      {
        title: "Services",
        items: [
          {
            title: "Compatibility & Pre-feasibility",
            subItems: ["Verify that your network is compatible with the Graph"],
          },
          {
            title: "The Graph Cluster Implementation",
            subItems: [
              "Deploy infrastructure that will contain subgraphs",
              "Deploy test subgraph to verify proper functionality",
              "Write an article about Graph being accessible on NETWORK and promote it",
            ],
          },
          {
            title: "The Graph Cluster Maintenance",
            subItems: [
              "Update components of the infrastructure in a timely",
              "Index new subgraphs",
              "Cleanup deprecated subgraphs",
              "Fix issues with malfunctioning subgraphs",
              "Help to fix developed subgraphs",
            ],
          },
          {
            title: "Subgraph Development Service",
            subItems: [
              "Work with the application developers to define events fetched from the blockchain",
              "Design optimal database schema for the applications",
              "Write the subgraph implementation",
              "Index, test, and continuously support the developed subgraphs",
            ],
          },
        ],
      },
      {
        title: "Deliverables",
        items: [
          "The Graph Cluster Infrastructure",
          "Github script to create infrastructure",
          "UI for User to see subgraph and access the endpoints",
          "Developer Tutorial: How to deploy subgraphs",
          "Monitoring & Subgraph error logging",
        ],
      },
    ],
    mediaData: {
      items: [
        {
          title: "Protofire x The Graph Partnership",
          link: "https://medium.com/protofire-blog/protofire-provides-implementation-services-in-the-graph-partnership-7e07591031d7",
        },
        {
          title: "Empowering Astar Network with The Graph Infrastructure",
          link: "https://medium.com/protofire-blog/empowering-astar-network-enhancing-data-accessibility-with-the-graph-infrastructure-c01ad5c70050",
        },
      ],
    },
    contact: {
      image: arsenii,
      name: "Arsenii Petrovich",
      lnUrl: "https://www.linkedin.com/in/arsenii-petrovich/",
      calendlyCallPostfix: "arsenii-s-petrovich",
    },
  },

  //--------------------------------------------------------------------

  validators: {
    metaTitle:
      "Validators: Expert Infrastructure Setup & Management | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Maximize TVL, MAU & liquidity with our expert validator setup & management. Simplify operations, improve metrics & focus on growth. Deploy in weeks.",
      },
      {
        name: "keywords",
        content:
          "Validator Infrastructure Management, Blockchain Node Optimization, Crypto Ecosystem Metrics, Decentralized Asset Management, Validator Performance Boost",
      },
    ],
    title: "Validators",
    description: [
      "We set up and maintain the environment and infrastructure for Validators to help them outperform TVL, MAU, liquidity levels, and more.",
    ],
    customers: [
      "Fuse",
      "Meter",
      "CrossFi",
      "Stratos",
      "DFK",
      "Avalanche",
      "Secret Network",
      "Lava Network",
      "Fluence",
    ],
    node: "DevOps",
    additionalContent: [
      {
        title: "Description",
        description: [
          "Tired of complicated management? Our engineers are one of the best DevOps teams in the world with deep knowledge of how a crypto ecosystem works, its metrics, tools, and pain points. TVL, MAU, and liquidity are the target metrics that we aim to grow as a result of our activities.",
          "We provide simple and fairly priced solutions that can be deployed in a few weeks for asset management, decentralization, or to rid developers of managing blockchain nodes in-house.",
        ],
      },
      {
        title: "Main metrics",
        items: ["APR", "Uptime", "Economical efficiency"],
      },
      {
        title: "Best suitable for",
        items: [
          "Validators",
          "Miners",
          "Indexers",
          "Full/Light/Archival nodes",
          "Witnesses, Relayers, Fishermen, Sentinels, and many more types of nodes that we are currently running in the crypto ecosystem",
        ],
      },
    ],
    mediaData: {
      items: [
        {
          title: "15 Best Practices for Validator Node Security",
          link: "https://medium.com/protofire-blog/15-best-practices-for-validator-node-security-5d396a636720",
        },
      ],
    },
    contact: {
      image: arsenii,
      name: "Arsenii Petrovich",
      lnUrl: "https://www.linkedin.com/in/arsenii-petrovich/",
      calendlyCallPostfix: "arsenii-s-petrovich",
    },
  },

  //--------------------------------------------------------------------

  // "genesis-ark": {
  //   title: "Genesis Ark",
  //   description: [
  //     "We deliver a simple, user-friendly way for blockchain networks with subchains to automatically onboard more dApps.",
  //   ],
  //   node: "DevOps",
  //   learnMore: {
  //     text: "Genesis Ark Appchain Solutions.",
  //     link: "https://docs.google.com/presentation/d/19hlZ3ZXoNtbhpUh1rTRhfHhpvmLE3kri/edit#slide=id.g2caa5bca271_0_4",
  //   },
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Genesis Ark by Protofire is an AWS solution for blockchain networks that are not growing due to appchain onboarding difficulties.",
  //         "Protofire suggests a cheap and simple solution that enables automated dApp deployment to a network in a few clicks. At pre-deployment, Genesis Arc is used for testing and production purposes.",
  //         "Appchain deployment simplifies network growth through the multiplication of user acquisition channels.",
  //       ],
  //     },
  //     {
  //       title: "Services / Types of Services",
  //       description: ["TODO: discuss - structure? Content?"],
  //     },
  //     {
  //       title: "Services / Types of Services / Deployment Options",
  //       description: ["TODO: discuss - structure? Content?"],
  //     },
  //   ],
  //   contact: {
  //     image: arsenii,
  //     name: "Arsenii Petrovich",
  //     lnUrl: "https://www.linkedin.com/in/arsenii-petrovich/",
  //     calendlyCallPostfix: "arsenii-s-petrovich",
  //   },
  // },

  //--------------------------------------------------------------------

  "rpc-node-hosting": {
    metaTitle:
      "Custom RPC Node Hosting: High-Performance Blockchain Infrastructure | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Enhance your blockchain ecosystem with our customized RPC endpoints. Robust APIs, dedicated support, and continuous improvement for optimal performance.",
      },
      {
        name: "keywords",
        content:
          "RPC node hosting, Blockchain infrastructure, Custom API endpoints, Blockchain DevOps, High-performance RPC",
      },
    ],
    title: "RPC Node Hosting",
    description: [
      "Protofire provides high-performance customized RPC endpoints for networks, platforms, protocols, and event organizers.",
    ],
    node: "DevOps",
    // learnMore: {
    //   text: "Filecoin RPC node hosting service.",
    //   link: "https://docs.google.com/presentation/d/16FwOf0zJ2ijnXfZ2De5yj7ILACBDnI2Y/edit?usp=sharing&ouid=102484339150103204331&rtpof=true&sd=true",
    // },
    additionalContent: [
      {
        title: "Description",
        description: [
          "Protofire deploys tools and processes to improve the safety and control of ecosystem nodes through customized RPC node hosting.",
          "Running RPC nodes goes far beyond simply hosting a node for us. It’s a whole mindset of providing accessible RPC and API to the community, gathering their feedback, and continuously improving the provided service to ensure the best service possible",
        ],
      },
      {
        title: "Main metrics",
        items: ["Amount of users", "Infrastructure costs", "Uptime"],
      },
      {
        title: "Deliverables",
        items: [
          "Stable robust set of APIs to be shared with users",
          "Customized curated environments that are being constantly improved",
          "A dedicated team working on improving your RPC/API endpoints",
          "Standard, Full-time, or Extended coverage",
          "Standard, Full-time, or Extended user support",
          "Prompt support on demand",
        ],
      },
    ],
    codeData: {
      items: [
        {
          title: "Filecoin Infrastructure",
          link: "https://github.com/glifio/filecoin-iac",
        },
        {
          title: "Filecoin Chart",
          link: "https://github.com/glifio/filecoin-chart",
        },
      ],
    },
    mediaData: {
      items: [
        {
          title: "Filecoin RPC node hosting service",
          link: "https://docs.google.com/presentation/d/16FwOf0zJ2ijnXfZ2De5yj7ILACBDnI2Y/edit?usp=sharing&ouid=102484339150103204331&rtpof=true&sd=true",
        },
        {
          title: "Glif praised a #1 project during the Retro-PGF grant",
          link: "https://medium.com/cryptoeconlab/a-deepdive-into-fil-retropgf-1-results-7e5a0bcdba08",
        },
        {
          title: "Tame the Behemoth or how to run an archive blockchain node",
          link: "https://medium.com/protofire-blog/tame-the-behemoth-or-how-to-run-an-archive-blockchain-node-b1fc13591ab5",
        },
      ],
    },
    contact: {
      image: arsenii,
      name: "Arsenii Petrovich",
      lnUrl: "https://www.linkedin.com/in/arsenii-petrovich/",
      calendlyCallPostfix: "arsenii-s-petrovich",
    },
  },
  //--------------------------------------------------------------------

  // "cost-optimization": {
  //   title: "Cost Optimization",
  //   description: [
  //     "Protofire helps blockchain-based projects and ecosystems optimize solution infrastructure and save thousands of dollars on operation and maintenance.",
  //   ],
  //   node: "DevOps",
  //   learnMore: {
  //     text: "Cost Optimization.",
  //     link: "https://docs.google.com/presentation/d/1s1r-lkLk-p4yA8c3y43Y6BwxsREt-Nlc/edit#slide=id.g211bee74fda_0_128",
  //   },
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Protofire thoroughly examines project infrastructure and provides a detailed list with architecture and maintenance cost optimization recommendations.",
  //         "We focus on AWS, GCP, and Azure optimizations and can give recommendations about other infrastructure.",
  //       ],
  //     },
  //     {
  //       title: "Services / Types of Services",
  //       description: ["TODO: discuss - IMAGES?"],
  //     },
  //     {
  //       title: "Services / Types of Services / Deployment Options",
  //       description: ["TODO: discuss - IMAGES?"],
  //     },
  //   ],
  //   mediaData: {
  //     items: [
  //       {
  //         title: "Filecoin (TODO LINK IS NEEDED)",
  //         link: "",
  //       },
  //     ],
  //   },
  //   contact: {
  //     image: arsenii,
  //     name: "Arsenii Petrovich",
  //     lnUrl: "https://www.linkedin.com/in/arsenii-petrovich/",
  //     calendlyCallPostfix: "arsenii-s-petrovich",
  //   },
  // },

  //--------------------------------------------------------------------

  // audit: {
  //   metaTitle: "Insured Smart Contract Audits | Protofire",
  //   meta: [
  //     {
  //       name: "description",
  //       content:
  //         "Boost protocol security with our Insured Audits. Reduce audit costs, attract institutional deposits, and increase TVL with staked USDC backing.",
  //     },
  //     {
  //       name: "keywords",
  //       content:
  //         "Insured smart contract audits, DeFi protocol security, Institutional deposit attraction, Blockchain risk assessment, TVL increase strategies",
  //     },
  //   ],
  //   title: "Insured Audits",
  //   description: [
  //     "Protofire helps protocols to attract users by improving and demonstrating its security.",
  //     "We designed Insured Audits to solve the problem of auditors not having skin in the game. Unlike alternatives, Protofire’s Insured Audits combine staked USDC/ERC20 tokens from delivery teams with USDC/ERC20 from 3rd party underwriters. Skin in the game from auditors and the ability to purchase optional deposit protection de-risks institutional deposits.",
  //   ],
  //   node: "SNP",
  //   // learnMore: {
  //   //   text: "Audit Services Proposal for IPOR",
  //   //   link: "https://docs.google.com/presentation/d/1EOMLagHGsT3u1kjuPWO0Ef1wXeWvGkW7aQ9TKdagDYs/edit#slide=id.g11eeb56b501_0_0",
  //   // },
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Most protocols, especially new ones, struggle to attract low-cost, long-term capital because they are missing certain risk-related features, or don’t provide certain assurances required by allocators.",
  //         "Asset managers such as funds, family offices, treasuries, and whales are willing to deploy even millions per allocation, even with a modest yield rate, however, the target protocol must check a few boxes when it comes to risk for such capital to be allocated.",
  //         "Protofire proposes Audit services to assess risks for protocols and boost their ecosystem’s growth.",
  //       ],
  //     },
  //     {
  //       title: "Opportunities for protocols",
  //       items: [
  //         "A Protocol/dApp becomes eligible for deposits from asset managers such as funds, family offices, and treasuries.",
  //         "Asset managers can allocate new deposits.",
  //         "TVL is increased from existing users sustainably without airdrop incentives.",
  //       ],
  //     },
  //     {
  //       title: "Expectations for the Insured Audits",
  //       items: [
  //         {
  //           title:
  //             "For Asset managers such as funds, family offices, and treasuries:",
  //           subItems: [
  //             "Start allocations because most of the risks are now offloaded to 3rd party underwriters, including Protofire.",
  //           ],
  //         },
  //         {
  //           title: "For Existing users:",
  //           subItems: ["Start increasing their allocations."],
  //         },
  //       ],
  //     },
  //     {
  //       title: "Service: Pre-audit",
  //       description: [
  //         'We will reduce the cost of third-party audits. The "second pair of eyes" will help you to minimize the number of findings, cover gaps in documentation and, as a sequence, reduce the Auditor\'s hours and your costs.',
  //       ],
  //       items: [
  //         "The cost of audits is reduced by 20%-50% ($50k-$250k).",
  //         "The duration of audits is reduced by 20%-40%.",
  //       ],
  //     },
  //     {
  //       title: "Service: Insured smart contract audits",
  //       description: [
  //         "Our researchers who stake as underwriters in pools that back your protocol insurance cover will perform audits. Audits include an Insurance Policy to cover up to 100% of depositor size, with optional upgrades covering individual depositors. Optionally, we will conduct audits for Oracles, if applicable.",
  //         "Audits backed by hard USDC, not just by brand value directly, facilitate new institutional depositors who like the option of insurance upgrades if they choose to increase allocations.",
  //       ],
  //     },
  //     {
  //       title: "Service: Institutional deposits bootstrapping",
  //       description: [
  //         "We will attract institutional depositors to make allocations to your protocol. A group of institutions is interested in opening allocations to new asset classes available in DeFi, as long as Protofire underwriters the risk and their initial deposits are insured with the ability to upgrade protection (i.e. for an initial deposit of $100k insured, they would upgrade to $5M on their own).",
  //         "Deposits of low-cost, sticky capital that doesn’t expect large yields.",
  //       ],
  //     },
  //     {
  //       title: "Stages",
  //       items: [
  //         {
  //           title: "Initial Assessment",
  //           subItems: [
  //             "High-level analysis of the scope",
  //             "Quotation and scope of services definition",
  //           ],
  //         },
  //         {
  //           title: "Audit",
  //           subItems: [
  //             "Use case exploration along with customer",
  //             "Audit of smart contracts, frontend infrastructure, or oracles in scope",
  //           ],
  //         },
  //         {
  //           title: "Report",
  //           subItems: [
  //             "Consolidation of findings, issues, risk classification and analysis",
  //             "Holistic maturity report assessment in the three proposed pillars",
  //             "Recommendations",
  //           ],
  //         },
  //         {
  //           title: "Remediation",
  //           subItems: [
  //             "The customer fixes issues following Protofire recommendations",
  //             "Protofire supports in case the customer needs clarification",
  //             "Complementary audit to check if issues were fixed",
  //           ],
  //         },
  //         {
  //           title: "Certification",
  //           subItems: [
  //             "Based on a fixed version of the application Protofire presents the final report",
  //             "A Protofire Certification Badge (ERC-721) is minted containing a description of the audit",
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  //   contact: {
  //     image: luis,
  //     name: "Luis Medeiros",
  //     lnUrl: "https://www.linkedin.com/in/lvgmedeiros/",
  //     calendlyCallPostfix: "luis-protofire",
  //   },
  // },

  //--------------------------------------------------------------------

  "oracle-integration": {
    metaTitle: "Custom Oracle Integration for Blockchain Networks | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Deploy compatible oracles for EVM and non-EVM networks. Enhance your blockchain in under 2 months.",
      },
      {
        name: "keywords",
        content:
          "Blockchain oracle integration, Custom oracle deployment, EVM-compatible oracles, Cross-chain data solutions, Blockchain network enhancement",
      },
    ],
    title: "Oracle Integration",
    description: [
      "Protofire deploys custom compatible oracles using the same data providers and node operators, allowing for protocols to go to their network without changing smart contracts.",
    ],
    node: "SNP",
    additionalContent: [
      {
        title: "Description",
        description: [
          "New EVM-compatible L1/L2 blockchain networks being released or in early stages understand the benefit of having compatible oracles to compete to attract protocols and ultimately users and TVL. Protofire addresses this challenge by deploying the demanded oracles in less than 2 months, with reliable price feeds and data sources. We provide similar security, using the same data providers and smart contracts.",
          "Protofire has been providing oracle integration services for years and is regularly expanding its expertise across new technologies.",
        ],
      },
      {
        title: "Services",
        items: [
          "Integrate oracles across several networks",
          "Develop repositories with files required to run nodes in a network",
          "Test integrations on EVM-compatible blockchains",
          "Test integrations on non-EVM-compatible blockchains",
          "Build cross-testnet faucets",
          "Develop adapters to enable access to high-quality data and enable extreme flexibility to connect smart contracts to premium web APIs",
        ],
      },
      {
        title: "Development stages",
        items: [
          {
            title: "Discovery phase:",
            subItems: [
              "Prepare testing environment",
              "Run compatibility tests",
              "Prepare a follow-up compatibility report",
            ],
          },
          {
            title: "Development phase:",
            subItems: [
              "Fix identified compatibility issues",
              "Prepare smart contracts according to the requirements",
            ],
          },
          {
            title: "Deployment phase:",
            subItems: [
              "Set up production infrastructure",
              "A spin-up centralized cluster of several nodes",
              "Set up Data Feeds on the mainnet",
            ],
          },
          {
            title: "Post-deployment phase:",
            subItems: ["Cluster maintenance"],
          },
        ],
      },
    ],
    technologyStack: {
      items: [
        {
          title: "Programming Languages",
          items: ["Go (golang)", "Typescript", "Node.js"],
        },
        {
          title: "Databases",
          items: ["GCP", "GKE", "Linux", "Kubernetes"],
        },
        {
          title: "Tools",
          items: ["Kubernetes", "Geth", "Ginkgo", "Docker"],
        },
        {
          title: "Infrastructure Layer",
          items: [
            "Bitcoin node",
            "Eth 1.0",
            "Eth 2.0 Node",
            "Oracles",
            "Kubernetes",
          ],
        },
      ],
    },
    codeData: {
      items: [
        {
          title: "Integrations",
          link: "https://github.com/smartcontractkit",
        },
      ],
    },
    contact: {
      image: luis,
      name: "Luis Medeiros",
      lnUrl: "https://www.linkedin.com/in/lvgmedeiros/",
      calendlyCallPostfix: "luis-protofire",
    },
  },

  //--------------------------------------------------------------------

  // insurance: {
  //   metaTitle:
  //     "DeFi Insurance Solutions: Protect Users & Boost TVL | Protofire",
  //   meta: [
  //     {
  //       name: "description",
  //       content:
  //         "Secure your DeFi project with customized insurance solutions. Protect users against risks, attract institutional capital, and increase TVL.",
  //     },
  //     {
  //       name: "keywords",
  //       content:
  //         "DeFi insurance solutions, Blockchain risk protection, Smart contract security, TVL growth strategies, Institutional crypto capital",
  //     },
  //   ],
  //   title: "Insurance Solutions",
  //   description: [
  //     "Using At_REPLACE_omica, Protofire builds comprehensive, customized Security Solutions that protect DeFi users against risks that prevent them from adopting your project or increasing their deposits.",
  //   ],
  //   node: "SNP",
  //   learnMore: {
  //     text: "Protofire Insurance Solutions with At_REPLACE_omica",
  //     link: "https://docs.google.com/presentation/d/1FsaM7m7eCM4fgkw4NB9_xTy8JMb6XA3bhAso7BiFWvs/edit#slide=id.g2846eb6929b_0_0",
  //   },
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "At_REPLACE_omica is a Risk Transfer protocol built for Web3 solutions and investors to launch their insurance products, offer protection to their users, and/or earn yield for providing protection. Automated, flexible, and perpetual insurance policies, think of At_REPLACE_omica as the Uniswap for risks. The next-generation insurance marketplace.",
  //       ],
  //     },
  //     {
  //       title: "Services",
  //       items: [
  //         "Create group policies in the backend or allow users to pick their protections and cover.",
  //         "Process claims parametrically, automatically, or based on off-chain decisions with multisig.",
  //         "Implement the Perpetual Policy: pay per second, cancel any time.",
  //         "Introduce multiple risk tranches for capital efficiency.",
  //         "Make parties profit in different roles: underwriting risk, attracting capital, selling policies, etc.",
  //         "Use insurance policies as a sales/marketing tool.",
  //       ],
  //     },
  //     {
  //       title: "Setup stages",
  //       items: [
  //         {
  //           title: "We test the demand.",
  //           subItems: [
  //             "We test and gauge the appetite and willingness to pay for protection. Test different premium costs (A/B test) to also have a signal on premium costs.",
  //           ],
  //         },
  //         {
  //           title: "Create a general protection.",
  //           subItems: [
  //             "We provide initial protections sponsored by your Protocol or Network and other stakeholders, offering minimum protection for all users.",
  //           ],
  //         },
  //         {
  //           title: "Attract underwrite capital",
  //           subItems: [
  //             "Protofire and At_REPLACE_omica bootstrap the capital pools. We then create a Safety Module to attract underwriting capital and create new yield opportunities for the community.",
  //           ],
  //         },
  //         {
  //           title: "Enable individual protections",
  //           subItems: [
  //             "We seamlessly integrate using At_REPLACE_omica SDK and widgets allowing users to hire specific protections for different risks and increase their coverages, creating a new revenue stream for the protocol.",
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: "Proven business results",
  //       items: [
  //         "30% TVL growth in 2 months from retail and large depositors",
  //         "Attract institutional, sticky capital, with 250K+ tickets",
  //         "Create an unfair competitive advantage with insurance",
  //       ],
  //     },
  //     {
  //       title: "Use cases - DeFi risks At_REPLACE_omica can easily protect against",
  //       items: [
  //         "Smart contract hacks and bugs",
  //         "Economic and oracle attacks",
  //         "Asset/ stablecoin depeg",
  //         "Turbo bug bounties",
  //         "Infrastructure SLAs",
  //         "Bridging and cross-chain",
  //       ],
  //     },
  //   ],
  //   customers: ["DeltaPrime", "QuickSwap", "LandX"],
  //   contact: {
  //     image: luis,
  //     name: "Luis Medeiros",
  //     lnUrl: "https://www.linkedin.com/in/lvgmedeiros/",
  //     calendlyCallPostfix: "luis-protofire",
  //   },
  // },

  //--------------------------------------------------------------------

  // "insurance-for-large-tvl-depositors": {
  //   title: "Insurance for large TVL depositors",
  //   description: [
  //     "Protofire develops frameworks of insurance solutions to attract users and increase customers’ TVL.",
  //   ],
  //   node: "SNP",
  //   // learnMore: {
  //   //   text: "Economic Security Proposal",
  //   //   link: "https://docs.google.com/presentation/d/1o-QN1mLKAn_i0yW8AEjrBN6XQYrdHERWhYNOKql3_yw/edit#slide=id.g1df92e58e05_0_13",
  //   // },
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "The Protofire’s framework is built based on a custom roadmap tuned up for the specific requirements of the customer or can be selected from the menu",
  //       ],
  //     },
  //     {
  //       title: "Services / Types of Services",
  //       description: ["TODO: discuss - structure? Content?"],
  //     },
  //     {
  //       title: "Services / Types of Services / Deployment Options",
  //       description: ["TODO: discuss - structure? Content?"],
  //     },
  //   ],
  //   contact: {
  //     image: luis,
  //     name: "Luis Medeiros",
  //     lnUrl: "https://www.linkedin.com/in/lvgmedeiros/",
  //     calendlyCallPostfix: "luis-protofire",
  //   },
  // },

  //--------------------------------------------------------------------

  // "insured-audit-services-institutional-tvl": {
  //   title: "Insured Audit Services & Institutional TVL",
  //   description: [
  //     "Protofire helps protocols attract users and grow TVL by improving and demonstrating the customer ecosystem’s security.",
  //   ],
  //   node: "SNP",
  //   // learnMore: {
  //   //   text: "Audit Services Proposal for IPOR",
  //   //   link: "https://docs.google.com/presentation/d/1EOMLagHGsT3u1kjuPWO0Ef1wXeWvGkW7aQ9TKdagDYs/edit#slide=id.g11eeb56b501_0_0",
  //   // },
  //   learnMoreAdditional: {
  //     text: "Economic Security Proposal",
  //     link: "https://docs.google.com/presentation/d/1o-QN1mLKAn_i0yW8AEjrBN6XQYrdHERWhYNOKql3_yw/edit#slide=id.g1df92e58e05_0_13",
  //   },
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Protofire provides Audit and TVL growth services to assess risks for protocols and boost their ecosystem’s growth.",
  //       ],
  //     },
  //   ],
  //   contact: {
  //     image: luis,
  //     name: "Luis Medeiros",
  //     lnUrl: "https://www.linkedin.com/in/lvgmedeiros/",
  //     calendlyCallPostfix: "luis-protofire",
  //   },
  // },

  //--------------------------------------------------------------------

  // cdp: {
  //   metaTitle: "Custom CDP Deployment | Protofire",
  //   meta: [
  //     {
  //       name: "description",
  //       content:
  //         "Deploy Liquity-based CDPs on your network. Create a native stablecoin, attract investors, and boost your DeFi ecosystem with zero-interest loans.",
  //     },
  //     {
  //       name: "keywords",
  //       content:
  //         "Liquity CDP deployment, Custom blockchain stablecoin, Zero-interest DeFi loans, Ecosystem token staking, Decentralized borrowing protocol",
  //     },
  //   ],
  //   title: "CDP (Liquity)",
  //   description: [
  //     "We deploy fully functional CDP products on customer networks and make fully functional templates for their users willing to extend it and create profitable businesses in the customer’s ecosystem.",
  //   ],
  //   node: "SNP",
  //   learnMore: {
  //     text: "Liquity-based CDP",
  //     link: "https://docs.google.com/presentation/d/1hbtXYDcq1Vx5F1JYENHe6BSZzLg7W-XhWbhqHsArtYs/edit#slide=id.g1dc099348c3_0_39",
  //   },
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Protofire forks, ports to Pact programming language, and adapts Liquity protocol to the customer’s network.",
  //         "Among the popular decentralized CDP solutions such as Maker DAO, Liquity/Prisma, and all of its forks, we propose to start with the Liquity codebase due to its long list of advantages.",
  //       ],
  //     },
  //     {
  //       title: "About Liquity",
  //       items: [
  //         "TVL: U$650M (U$4.5B max) on Ethereum.",
  //         "Size: 18th largest DeFi protocol, and 3rd largest CDP, only behind MakerDAO and JustStables.",
  //       ],
  //     },
  //     {
  //       title: "The path to have a native stablecoin",
  //       items: [
  //         "A robust stablecoin that is algorithmically pegged.",
  //         "CDP with zero interest that promotes long-term investments.",
  //         "Deployment, customization, maintenance, and operation.",
  //       ],
  //     },
  //     {
  //       title: "Benefits for investors",
  //       items: [
  //         "Customer investors have a place to stake customer tokens and earn rewards, which may promote a price increase for the customer token.",
  //         "Investors earn rewards for staking LUSD too, which pushes LUSD to become a robust stablecoin.",
  //         "Investors systematically earn rewards from two sources in this system.",
  //         "Investors are provided other means of profiting with Liquity.",
  //       ],
  //     },
  //     {
  //       title: "Benefits for the ecosystem",
  //       items: [
  //         {
  //           title: "CDP with zero or low interest:",
  //           subItems: ["To attract new investors."],
  //         },
  //         {
  //           title: "Native stablecoin:",
  //           subItems: ["To promote long-term investments."],
  //         },
  //         {
  //           title: "Customer token staking:",
  //           subItems: ["To strengthen the token price."],
  //         },
  //         {
  //           title: "Enhancing the DeFi ecosystem:",
  //           subItems: ["For general ecosystem growth."],
  //         },
  //         {
  //           title: "Several investing strategies.",
  //           subItems: [],
  //         },
  //       ],
  //     },
  //     {
  //       title: "What can investors do with Liquity?",
  //       items: [
  //         "Deposit your tokens and receive LUSD stablecoin for depositing.",
  //         "Stake LUSD on the stability pool, receive LQTY for staking and earn rewards.",
  //         "The rewards come from the accounts that get liquidated.",
  //         "Stake LQTY and receive rewards from deposit fees.",
  //       ],
  //     },
  //     {
  //       title: "Other methods of making money with Liquity",
  //       items: [
  //         "Stake with LUSD on LP.",
  //         "Stake with LUSD LP on a pool to earn incentivized rewards.",
  //         "Redemption arbitrage strategies.",
  //         "Liquidating positions.",
  //       ],
  //     },
  //   ],
  //   customers: ["Swarm", "BitUSD"],
  //   mediaData: {
  //     items: [
  //       {
  //         title: "Swarm Markets DEX from Protofire",
  //         link: "https://protofire.io/projects/swarm-dex",
  //       },
  //     ],
  //   },
  //   contact: {
  //     image: luis,
  //     name: "Luis Medeiros",
  //     lnUrl: "https://www.linkedin.com/in/lvgmedeiros/",
  //     calendlyCallPostfix: "luis-protofire",
  //   },
  // },

  //--------------------------------------------------------------------

  // "staking-modules": {
  //   metaTitle: "Custom Staking Modules | Protofire",
  //   meta: [
  //     {
  //       name: "description",
  //       content:
  //         "Protofire implements tailored staking mechanisms to incentivize ecosystem growth. Optimize for DEXs, lending platforms & stablecoins. Start your 2-week trial now.",
  //     },
  //     {
  //       name: "keywords",
  //       content:
  //         "Custom Staking Mechanisms, Protocol Growth Optimization, DeFi Ecosystem Incentives, Blockchain Staking Solutions, Token Economy Design",
  //     },
  //   ],
  //   title: "Staking Modules",
  //   description: [
  //     "We introduce staking mechanisms for platforms and protocols to maximize rapid success in the mid and long term.",
  //   ],
  //   node: "SNP",
  //   // learnMore: {
  //   //   text: "Staking with Protofire",
  //   //   link: "https://docs.google.com/presentation/d/15CNF89i9FF5GxAMuiriO3LAEujazlPTthaO_JUOY3rU/edit#slide=id.g2d35dd80327_0_1263",
  //   // },
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Protofire implements staking mechanisms that boost growth by incentivizing different parties in the customer’s ecosystem. ",
  //       ],
  //     },
  //     {
  //       title: "Staking Mechanisms for a Staking Module",
  //       items: [
  //         "Stake to Earn veTOKEN (governance power)",
  //         "Stake to Earn Incentives",
  //         "Stake to provide liquidity on the pools and earn swap fees and incentives, or stake by performing arbitrage with centralized exchanges",
  //       ],
  //     },
  //     {
  //       title: "Staking mechanisms for a Lending or DEX",
  //       items: [
  //         "TOKEN holders",
  //         "Users (Buyers and Sellers)",
  //         "Deployers",
  //         "OLP",
  //         "Stake TOKEN combined with other liquid assets to earn swap fees and incentives, and stake by performing arbitrage with centralized exchanges",
  //       ],
  //     },
  //     {
  //       title: "Staking Mechanisms for a Stablecoin",
  //       items: [
  //         "TOKEN holders",
  //         "Users (Buyers and Sellers)",
  //         "Stake other assets (stables, LP tokens, etc.) to mint yourUSD",
  //         "Stake TOKEN/veTOKEN in stability pools to earn fees",
  //       ],
  //     },
  //     {
  //       title: "Parties that benefit from staking modules",
  //       items: [
  //         "Delegators: Customer token holders/investors/users, buyers, and sellers",
  //         "Deployers: Equivalent to validators responsible for scaling the customer’s infrastructure",
  //         "OLP: Liquidity Providers responsible for providing liquidity to settle payments within the customer platform",
  //         "Customer token holders",
  //       ],
  //     },
  //     {
  //       title: "Key stages",
  //       items: [
  //         "Protofire secures a project team for 2 weeks as a trial period.",
  //         "If the trial result is achieved: Protofire maintains the team of the selected engineers after the first 2 weeks. The team performance and allocations are measured during monthly meetings.",
  //         "If the trial result is not achieved: The project can request a replacement or offboard a team without notice during the first 2 weeks. In this case, the customer pays only for the worked hours during the trial period.",
  //       ],
  //     },
  //   ],
  //   customers: ["ZEEBU", "Aethir", "Saga", "Flare", "Balancer"],
  //   contact: {
  //     image: luis,
  //     name: "Luis Medeiros",
  //     lnUrl: "https://www.linkedin.com/in/lvgmedeiros/",
  //     calendlyCallPostfix: "luis-protofire",
  //   },
  // },

  //--------------------------------------------------------------------

  marketing: {
    metaTitle:
      "Web3 Marketing: Boost TVL & User Adoption for DeFi Projects | Protofire",
    meta: [
      {
        name: "description",
        content:
          "Drive developer adoption and community growth for Web3 projects. Tailored strategies to enhance brand awareness, engage users, and maximize your DeFi impact.",
      },
      {
        name: "keywords",
        content:
          "Web3 marketing strategies, DeFi community building, Blockchain user acquisition, Crypto influencer partnerships, Web3 developer adoption",
      },
    ],
    title: "Web3 Marketing",
    description: [
      "We build vibrant communities and drive developer adoption for Web3 and DeFi projects using tailored strategies that enhance brand awareness, engage users, and connect you with key industry influencers to maximize your impact.",
    ],
    node: "Marketing",
    additionalContent: [
      {
        title: "Description",
        description: [
          "We help DAOs, chains, protocols, and networks grow their TVL, attract Leads and acquire Users to their ecosystems through community building, content creation, PR, PPC (web2 and web3-native channels), email marketing activities, advertising networks, events, and partnerships.",
          "Using our connections, we introduce customer projects to the communities through articles on trusted sources, influencer posts, seeding on social media, storytelling, marketing projects, and collateral.",
          "As a B2B-focused DAO addressing the pain points of B2B and B2C customers, we understand the key drivers for the ecosystem’s TVL and user base growth. Open rates of 90% are not a big deal for us because we’ve seen them going up to 140% on LinkedIn! (Yes, recipients were probably forwarding our messages to others. Isn’t that great?)",
          "We will identify your potential customers, increase your brand awareness, and drive your revenue.",
        ],
      },
      {
        title: "Services",
        items: [
          "Brand identity development",
          "Market research",
          "Audience segmentation",
          "Content creation",
          "Social media management",
          "Community management",
          "Reactivation strategies",
          "Developer adoption",
          "Lead generation",
          "User acquisition",
          "KOL marketing, influencer partnerships",
          "Media relations",
          "Crisis management",
          "Guest posting",
          "Event management",
          "Referral program",
          "Measurement and analytics",
        ],
      },
    ],
    mediaData: {
      items: [
        {
          title: "Offline and Side event support Services by Protofire",
          link: "https://docs.google.com/presentation/d/1o3Xp-Ez4YNFqq57nta54NvBBWtzH5eqG-3pu8JUwV40/edit?ouid=100110307947724580970&usp=slides_home&ths=true",
        },
        {
          title: "PR Push for Brand Recognition Growth",
          link: "https://docs.google.com/presentation/d/1wjhhQ_nBaHjoKnDOVbhNwICbEFOqnonxR5DZfG3b5d8/edit#slide=id.p1",
        },
        {
          title: "Deploying Safe, Driving User Growth & Developer Adoption",
          link: "https://medium.com/subspace-network/autonomys-x-protofire-driving-user-growth-developer-adoption-999eaf3c9b20",
        },
      ],
    },
    contact: {
      image: kirill,
      name: "Kirill Artsymenia",
      lnUrl: "https://www.linkedin.com/in/kirillartsymenia/",
      calendlyCallPostfix: "kiryl-protofire",
      text: "Schedule a call with our Head of Marketing to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //--------------------------------------------------------------------

  // bridges: {
  //   metaTitle:
  //   "Secure Cross-Chain Bridges: Reliable Blockchain Connectivity Solutions | Protofire",
  // meta: [
  //   {
  //     name: "description",
  //     content:
  //       "Protofire builds comprehensive, secure blockchain bridges with multi-layer consensus, automated monitoring, and optional insurance. Enhance cross-chain connectivity today.",
  //   },
  //   {
  //     name: "keywords",
  //     content:
  //       "Cross-Chain Bridges, Blockchain Interoperability, Decentralized Consensus Mechanism, Secure Token Transfers, Multi-Chain Integration",
  //   },
  // ],
  //   title: "Bridges",
  //   description: [
  //     "Protofire builds comprehensive Bridges for network ecosystems and develops reliable end-to-end solutions for protocols and retail and institutional users who need cross-chain and economic security features.",
  //   ],
  //   node: "SNP",
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Protofire implements cross-chain bridging solutions for ecosystems and users looking to improve their systems' security.",
  //       ],
  //     },
  //   ],
  //   customers: ["SuperRare"],
  //   contact: {
  //     image: luis,
  //     name: "Luis Medeiros",
  //     lnUrl: "https://www.linkedin.com/in/lvgmedeiros/",
  //     calendlyCallPostfix: "luis-protofire",
  //   },
  // },

  //--------------------------------------------------------------------

  // "marketing-pr": {
  //   title: "Marketing PR",
  //   description: [
  //     "We connect customers with Web3 and DeFi media to propel their brand awareness and customer loyalty growth.",
  //   ],
  //   node: "Marketing",
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "With years in the Web3 communities, we’ve nurtured strong connections with the industry’s best-known Web3 and crypto-related sources of all tiers. Using our connections, we introduce customer projects to the communities through articles on trusted sources, influencer posts, seeding on social media, storytelling, marketing projects, and collateral.",
  //       ],
  //     },
  //   ],
  //   contact: {
  //     image: kirill,
  //     name: "Kirill Artsymenia",
  //     lnUrl: "https://www.linkedin.com/in/kirillartsymenia/",
  //     calendlyCallPostfix: "kiryl-protofire",
  //     text: "Schedule a call with our Head of Marketing to receive practical recommendations and a prompt proposal for upgrading your solution.",
  //   },
  // },

  //--------------------------------------------------------------------

  // "community-building-and-management": {
  //   title: "Community Building and Management",
  //   description: [
  //     "We’re all about building strong, engaged communities around your Web3 project. Our team crafts strategies to connect with your audience, keeping them loyal and active.",
  //   ],
  //   node: "Marketing",
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Community building is the key driver for growth in the decentralized world. Communities foster connection and loyalty among members, enhancing customer engagement and retention.",
  //         "After years of fruitful collaboration with The Graph, Filecoin, MakerDAO, Avalanche, Polkadot, Safe, and others we’ve defined the key approaches in community building to be the following: defining the community's purpose, encouraging participation, leveraging social media, co-creating content, and hosting events.",
  //         "Through feedback and constant news sharing, protocols can strengthen relationships and amplify brand advocacy.",
  //       ],
  //     },
  //   ],
  //   customers: ["IoTeX"],
  //   contact: {
  //     image: kirill,
  //     name: "Kirill Artsymenia",
  //     lnUrl: "https://www.linkedin.com/in/kirillartsymenia/",
  //     calendlyCallPostfix: "kiryl-protofire",
  //     text: "Schedule a call with our Head of Marketing to receive practical recommendations and a prompt proposal for upgrading your solution.",
  //   },
  // },

  //--------------------------------------------------------------------

  "ppc-marketing": {
    title: "Lead Generation and User Acquisition",
    description: [
      "We help DAOs, chains, protocols, and networks attract Leads and acquire Users to their ecosystems through PPC (web2 and web3-native channels), Email marketing activities, and Advertising networks.",
    ],
    node: "Marketing",
    additionalContent: [
      {
        title: "Description",
        description: [
          "As a B2B-focused DAO addressing the pain points of B2B and B2C customers, we understand the key drivers for the ecosystem’s TVL and user base growth. Open rates of 90% are not a big deal for us because we’ve seen them going up to 140% on LinkedIn! (Yes, recipients were probably forwarding our messages to others. Isn’t that great?).",
          "In our Lead Generation and User Acquisition activities, we identify your potential customers, increase your brand awareness, and drive your revenue. As a result, you will expand your customer base, improve sales efficiency, and gain valuable market insights. Both strategies benefit from data-driven decision-making and continuous optimization based on performance metrics.",
        ],
      },
      {
        title: "Lead Generation approaches",
        items: [
          "Create valuable content to attract and engage potential leads",
          "Utilize targeted social media advertising",
          "Implement email nurturing campaigns",
          "Optimize website for search engines and lead capture (SEO)",
        ],
      },
      {
        title: "User Acquisition approaches",
        items: [
          "Develop a multi-channel paid advertising strategy",
          "Launch and optimize referral programs",
          "Enhance app store presence and visibility",
          "Collaborate with influencers and industry partners",
        ],
      },
      {
        title: "Target audiences",
        items: [
          "User Acquisition",
          "Developers adoption",
          "Institutional Leads",
        ],
      },
      {
        title: "Examples of user acquisition activities",
        items: [
          {
            title: "Developers",
            subItems: ["Run hackathons or competitions to attract developers."],
          },
          {
            title: "Institutional Investors and Partners",
            subItems: [
              "Utilize email marketing, interviews, and push awareness campaigns.",
            ],
          },
          {
            title: "Dapps Builders",
            subItems: [
              "Engage and update a database of contacts about a project’s features. Invite developers to join and build on the network.",
            ],
          },
          {
            title: "Research",
            subItems: [
              "Research your existing clients, developers, and node owners through interviews and questionnaires, then summarize the findings with ideas for the next actions and support needs. We did similar research for Balancer in the past.",
            ],
          },
          {
            title: "Node Leaderboard",
            subItems: [
              "Build a leaderboard for nodes to encourage competition and engagement.",
            ],
          },
          {
            title: "Direct Email Marketing",
            subItems: [
              "Our clients, from several time zones and crypto industries (Dex, L1s, L2s, NFT projects, and much more), all require direct email marketing campaigns with different objectives, audiences, and calls to action.",
            ],
          },
        ],
      },
    ],
    contact: {
      image: kirill,
      name: "Kirill Artsymenia",
      lnUrl: "https://www.linkedin.com/in/kirillartsymenia/",
      calendlyCallPostfix: "kiryl-protofire",
      text: "Schedule a call with our Head of Marketing to receive practical recommendations and a prompt proposal for upgrading your solution.",
    },
  },

  //--------------------------------------------------------------------

  // "offline-and-side-events-support": {
  //   title: "Offline and Side Event Support",
  //   description: [
  //     "Our Marketing team can take care of your event attendance from start to end, including event selection, booth design and assembling, and side event management, with a strong push through social media.",
  //   ],
  //   node: "Marketing",
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Protofire attends all of the largest and most exciting events in the Web3 world. With our rich event management experience, we can select the most effective events for you, negotiate and settle down the agreement, design and arrange your booth and merch, and manage your side events digitally and in person.",
  //         "Depending on the volume of help you need, our support can expand from single-time help to 360-degree management of your events.",
  //       ],
  //     },
  //   ],
  //   customers: ["At_REPLACE_omica"],
  //   mediaData: {
  //     items: [
  //       {
  //         title: "Offline and Side event support Services by Protofire",
  //         link: "https://docs.google.com/presentation/d/1o3Xp-Ez4YNFqq57nta54NvBBWtzH5eqG-3pu8JUwV40/edit?ouid=100110307947724580970&usp=slides_home&ths=true",
  //       },
  //     ],
  //   },
  //   contact: {
  //     image: kirill,
  //     name: "Kirill Artsymenia",
  //     lnUrl: "https://www.linkedin.com/in/kirillartsymenia/",
  //     calendlyCallPostfix: "kiryl-protofire",
  //     text: "Schedule a call with our Head of Marketing to receive practical recommendations and a prompt proposal for upgrading your solution.",
  //   },
  // },

  //--------------------------------------------------------------------

  // "kol-marketing": {
  //   title: "KOL Marketing",
  //   description: [
  //     "We can connect with Key Opinion Leaders (KOLs) and mass media focused on our industry, generating awareness and word-of-mouth, pushing your brand further into the public consciousness.",
  //   ],
  //   node: "Marketing",
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "KOL Marketing utilizes the credibility of influencers, or Key Opinion Leaders, to build trust and influence purchasing decisions. KOLs enhance brand credibility and expand reach to targeted audiences.",
  //         "We can identify KOLs for your goals, create valuable content around your brand, and distribute it across different channels.",
  //       ],
  //     },
  //   ],
  //   contact: {
  //     image: kirill,
  //     name: "Kirill Artsymenia",
  //     lnUrl: "https://www.linkedin.com/in/kirillartsymenia/",
  //     calendlyCallPostfix: "kiryl-protofire",
  //     text: "Schedule a call with our Head of Marketing to receive practical recommendations and a prompt proposal for upgrading your solution.",
  //   },
  // },

  //--------------------------------------------------------------------

  // qa: {
  //   title: "Quality Assurance",
  //   description: [
  //     "We provide Quality Assurance services for Web3 projects, networks, and ecosystems to reduce the cost of quality user experience, automate monitoring and alerts, and improve QA performance.",
  //   ],
  //   node: "Alejandro",
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Delayed bug fixing, lack of automation, and uneven QA workload will result in the loss of TVL. Protofire inspects the customer’s QA processes and infrastructure and provides full-cycle QA support with improvement recommendations to mitigate these risks.",
  //       ],
  //     },
  //   ],
  //   customers: ["Maple Finance", "IPOR"],
  //   contact: {
  //     image: alejandro,
  //     name: "Alejandro Losa",
  //     lnUrl: "https://www.linkedin.com/in/alejandrolosa/ ",
  //     calendlyCallPostfix: "alejandro-protofire",
  //     text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
  //   },
  // },

  //--------------------------------------------------------------------

  // "staff-augmentation": {
  //   title: "Staff Augmentation",
  //   description: [
  //     "Protofire can set up a dream team of engineers for your project with on-demand allocation, regular reporting, and a clear pricing plan.",
  //   ],
  //   node: "Alejandro",
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "Protofire is a Developer DAO of 90 engineers and growth hackers who have worked with all the biggest Web3 networks and chains since 2016. Depending on the volume of jobs required, we can allocate engineers and teams of any size to your protocol/network/project.",
  //         "Since 2019, Protofire has been offering an Outcome-based compensation model to incentivize and engage the engineers working on projects in the long term. The payment of this price component is based on the successful performance provided by the hired team members and can be paid in your tokens or other tokens.",
  //       ],
  //     },
  //   ],
  //   customers: ["TKN"],
  //   contact: {
  //     image: alejandro,
  //     name: "Alejandro Losa",
  //     lnUrl: "https://www.linkedin.com/in/alejandrolosa/ ",
  //     calendlyCallPostfix: "alejandro-protofire",
  //     text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
  //   },
  // },

  //--------------------------------------------------------------------

  // At_REPLACE_omica: {
  //   title: "At_REPLACE_omica",
  //   description: [
  //     "Protofire and At_REPLACE_omica build comprehensive, customized Security Solutions that protect DeFi users against risks that prevent them from adopting your project or increasing their deposits.",
  //   ],
  //   node: "At_REPLACE_omica",
  //   learnMore: {
  //     text: "At_REPLACE_omica",
  //     link: "https://docs.google.com/presentation/d/1FsaM7m7eCM4fgkw4NB9_xTy8JMb6XA3bhAso7BiFWvs/edit#slide=id.g2846eb6929b_0_0",
  //   },
  //   additionalContent: [
  //     {
  //       title: "Description",
  //       description: [
  //         "To help DeFi users avoid the risk of smart contract hacks/bugs, asset depeg, oracle manipulation, governance attacks, anon team rug pulls, web2/front-end infra, bridges, and others, Protofire and At_REPLACE_omica, a Risk Transfer protocol built for Web3 solutions and investors, offer protection to their users, and/or earn yield for protection.",
  //       ],
  //     },
  //   ],
  //   customers: ["DeltaPrime", "QuickPerps", "LandX"],
  //   contact: {
  //     image: alejandro,
  //     name: "Alejandro Losa",
  //     lnUrl: "https://www.linkedin.com/in/alejandrolosa/",
  //     calendlyCallPostfix: "alejandro-protofire",
  //     text: "Schedule a call with our Blockchain Solution Architect to receive practical recommendations and a prompt proposal for upgrading your solution.",
  //   },
  // },
};
