import axios from "axios";

/* eslint-disable import/prefer-default-export */
export const handleS3Download = async (fileUrl: string, fileName: string) => {
  try {
    const response = await axios.get(fileUrl, {
      responseType: "blob", // Ensure the response is a Blob
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Download failed:", error);
  }
};
