import { Link } from "react-router-dom";
import { ReactComponent as ChevronRightDouble } from "../assets/images/chevronRightDouble.svg";
import "./serviceItem.css";

type OtherServiceItemProps = {
  indexNumber: string;
  title: string;
  link: string;
  subItems: string[];
  className: string;
  isOuterLink: boolean;
};

function OtherServiceItem({
  indexNumber,
  title,
  link,
  subItems,
  className,
  isOuterLink = false,
}: OtherServiceItemProps) {
  const LinkContent = (
    <>
      <div
        className="absolute text-[64px] leading-[72px] -top-10"
        style={{
          fontFamily: "Onest-SemiBold",
          background:
            "linear-gradient(180deg, rgba(241, 248, 255, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%)",
          ["-webkit-background-clip" as any]: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {indexNumber}
      </div>
      <h3 className="text-2xl mb-4 xl:max-w-[186px] transition">{title}</h3>
    </>
  );
  return (
    <div className={`relative text-white100 ${className}`}>
      {isOuterLink ? (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="link-wrapper underline underline-offset-2"
        >
          {LinkContent}
        </a>
      ) : (
        <Link to={link} className="link-wrapper underline underline-offset-2">
          {LinkContent}
        </Link>
      )}
      <ul>
        {subItems.map((subItem) => (
          <li className="mt-4 flex items-center" key={`${title}-${subItem}`}>
            <ChevronRightDouble className="mr-4 min-w-[20px]" />
            <span>{subItem}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default OtherServiceItem;
