/* eslint-disable no-nested-ternary */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { serviceDetailsMap } from "./data";
import { ReactComponent as DownloadArrow } from "../assets/images/downloadArrow.svg";
import serviceBg1 from "../assets/services/bgMain1.png";
import serviceBg2 from "../assets/services/bgMain2.png";
import serviceBg3 from "../assets/services/bgMain3.png";
import serviceBg4 from "../assets/services/bgMain4.png";
import serviceBgLines from "../assets/services/bgLines.png";
import dividerLine from "../assets/services/divider-line.svg";
// import { ReactComponent as Lamp } from "../assets/projects/lamp.svg";
// import { ReactComponent as PaperClip } from "../assets/projects/paperClip.svg";
import BookCallWith from "../common/bookCallWith";
import { ReactComponent as ChevronRightDouble } from "../assets/services/chevronRightDoubleOrange.svg";
import { redirectMap } from "../../utils/redirects";
import { handleS3Download } from "../../utils/downloadFromS3";

function DownloadPdfBtn({
  linkUrl,
  pdfTitle,
}: {
  linkUrl: string;
  pdfTitle: string;
}) {
  return (
    <div
      className="text-xs flex items-center gap-1 bg-white10 rounded-md px-2 py-1.5"
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={() => {
        handleS3Download(linkUrl, pdfTitle);
      }}
    >
      <DownloadArrow />
      PDF
    </div>
  );
}

function ServiceDetails() {
  const { serviceId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const serviceDetails = serviceDetailsMap[serviceId as string];

  useEffect(() => {
    if (redirectMap[pathname]) {
      navigate(redirectMap[pathname]);
    }
  }, [navigate, pathname]);

  return (
    <div
      className="bg-no-repeat bg-auto pb-[150px]"
      style={{ backgroundImage: serviceDetails ? `url(${serviceBg1})` : "" }}
    >
      <Helmet>
        {serviceDetails?.metaTitle && <title>{serviceDetails.metaTitle}</title>}
        {serviceDetails?.meta?.map((metaItem) => (
          <meta
            name={metaItem.name}
            key={metaItem.content}
            content={metaItem.content}
          />
        ))}
      </Helmet>
      <div
        className="bg-no-repeat bg-auto bg-right-top min-h-[460px]"
        style={{ backgroundImage: `url(${serviceBg2})` }}
      >
        {serviceDetails ? (
          serviceDetails.isAudit ? (
            <div>
              <div className="max-w-[1120px] mx-auto pt-[80px]">
                <div className="pt-[100px] md:pt-[120px] text-white100">
                  <h1
                    className="text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
                    style={{ fontFamily: "Onest-SemiBold" }}
                  >
                    Audited Companies
                  </h1>
                  {serviceDetails.data.map((item) => (
                    <p
                      key={item.title}
                      style={{ fontFamily: "Onest-Light" }}
                      className="mt-6 mb-0 text-white100 text-base md:text-lg flex"
                    >
                      {item.title} (Last Audit - {item.lastAuditDate}){" "}
                      <div className="ml-4">
                        <DownloadPdfBtn
                          linkUrl={item.pdfLink}
                          pdfTitle={item.pdfTitle}
                        />
                      </div>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="max-w-[1120px] mx-auto pt-[80px]">
                <section className="mx-4 md:mx-6 xl:mx-0">
                  <div className="pt-[100px] md:pt-[120px] text-white100 md:max-w-[544px]">
                    <h1
                      className="text-[32px] leading-[40px] md:text-5xl md:leading-[56px] xl:text-[64px] xl:leading-[72px]"
                      style={{ fontFamily: "Onest-SemiBold" }}
                    >
                      {serviceDetails.title}
                    </h1>
                    {serviceDetails.description.map((desc: string) => (
                      <p
                        key={desc}
                        style={{ fontFamily: "Onest-Light" }}
                        className="mt-6 mb-0 text-white100 text-base md:text-lg"
                      >
                        {desc}
                      </p>
                    ))}
                  </div>
                </section>

                {serviceDetails.descriptionExtra ? (
                  <section className="text-white100 mx-4 md:mx-6 xl:mx-0">
                    {serviceDetails.descriptionExtra.map((descItem) => (
                      <div className="pt-[56px]">
                        <h2
                          className="text-2xl md:text-[32px] md:leading-[40px] xl:text-[40px] xl:leading-[48px]"
                          style={{ fontFamily: "Onest-SemiBold" }}
                        >
                          {descItem.title}
                        </h2>
                        {descItem.description.map((desc: string) => (
                          <p className="text-base mt-6" key={desc}>
                            {desc}
                          </p>
                        ))}
                        {descItem.points?.title ? (
                          <p className="text-base mt-6">
                            {descItem.points.title}
                          </p>
                        ) : (
                          ""
                        )}
                        {descItem.points?.items ? (
                          <ul>
                            {descItem.points.items.map((item) => (
                              <li
                                className="text-base mt-2 flex items-center"
                                key={item}
                              >
                                <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                <span>{item}</span>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </section>
                ) : (
                  ""
                )}

                <section
                  className="mx-4 md:mx-6 xl:mx-0 bg-no-repeat bg-auto bg-right-top"
                  style={{ backgroundImage: `url(${serviceBgLines})` }}
                >
                  <div className="pt-[120px] md:pt-[168px] text-white100">
                    <h2
                      className="text-2xl md:text-[32px] md:leading-[40px] xl:text-[40px] xl:leading-[48px]"
                      style={{ fontFamily: "Onest-SemiBold" }}
                    >
                      What We Can Provide
                    </h2>
                    <div className="mt-[56px] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
                      {serviceDetails.providers.map(
                        (item: { title: string; description: string }) => (
                          <div
                            key={item.title}
                            className="p-8 rounded-lg bg-white5"
                          >
                            <h4 className="text-lg text-white100">
                              {item.title}
                            </h4>
                            <p className="text-white70 text-base mt-6">
                              {item.description}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </section>

                {/* <section className="mx-4 md:mx-6 pt-[120px] md:pt-[168px] xl:mx-0">
                <div className="text-yellow text-[40px]">TODO: video?</div>
              </section> */}
              </div>

              <div
                style={{ backgroundImage: `url(${serviceBg3})` }}
                className="bg-no-repeat bg-contain bg-right-top"
              >
                <div className="max-w-[1120px] mx-auto pt-[20px]">
                  <div className="mt-[120px] md:mt-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

                  <section
                    className="mx-4 md:mx-6 xl:mx-0 bg-no-repeat bg-auto bg-right-top pt-[120px] md:pt-[168px]"
                    style={{ backgroundImage: `url(${serviceBgLines})` }}
                  >
                    <h2
                      className="text-2xl md:text-[32px] md:leading-[40px] xl:text-[40px] xl:leading-[48px] text-white100"
                      style={{ fontFamily: "Onest-SemiBold" }}
                    >
                      {serviceDetails.opportunitiesTitle}
                    </h2>
                    {serviceDetails.opportunitiesDescription?.length
                      ? serviceDetails.opportunitiesDescription.map(
                          (desc: string) => (
                            <p
                              key={desc}
                              style={{ fontFamily: "Onest-Light" }}
                              className="mt-6 mb-0 text-white100 text-base md:text-lg"
                            >
                              {desc}
                            </p>
                          )
                        )
                      : ""}
                    <div className="mt-[56px] grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-8">
                      {serviceDetails.opportunities.map(
                        (item: string, index: number) => (
                          <div
                            className="w-[204px] h-[204px] text-base text-white100 rounded-full flex items-center justify-center px-6"
                            key={item}
                            style={{
                              background:
                                index % 2 === 1
                                  ? "linear-gradient(0deg, rgba(241, 248, 255, 0.16) 0%, rgba(145, 149, 153, 0) 100%)"
                                  : "linear-gradient(180deg, rgba(241, 248, 255, 0.16) 0%, rgba(145, 149, 153, 0) 100%)",
                            }}
                          >
                            <span className="text-center">{item}</span>
                          </div>
                        )
                      )}
                    </div>
                  </section>
                  {/* <div className="mt-[120px] md:mt-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
                <section className="mx-4 md:mx-6 pt-[120px] md:pt-[168px] xl:mx-0">
                  <div className="text-yellow text-[40px]">
                    TODO: Technology stack?
                  </div>
                </section> */}
                </div>
              </div>

              <div
                style={{ backgroundImage: `url(${serviceBg4})` }}
                className="bg-no-repeat bg-contain bg-left-top"
              >
                <div className="max-w-[1120px] mx-auto pt-[20px]">
                  <div className="mt-[120px] md:mt-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />

                  <section className="mx-4 md:mx-6 xl:mx-0 pt-[120px] md:pt-[150px]">
                    <h2
                      className="text-2xl md:text-[32px] md:leading-[40px] xl:text-[40px] xl:leading-[48px] text-white100"
                      style={{ fontFamily: "Onest-SemiBold" }}
                    >
                      Types of Services
                    </h2>
                    <div className="mt-[56px] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
                      {serviceDetails.tabSubservices.map((tabItem) => (
                        <div
                          style={{ backdropFilter: "blur(56px)" }}
                          key={tabItem.title}
                          className="text-white100 bg-white5 p-8 rounded-lg"
                        >
                          <h4 className="text-2xl">{tabItem.title}</h4>
                          {tabItem.description.map((descr: string) => (
                            <p
                              className="mt-4 text-base text-white55"
                              key={descr}
                            >
                              {descr}
                            </p>
                          ))}
                          {tabItem.benefits?.length ? (
                            <>
                              <div
                                className="h-[1px] my-8"
                                style={{
                                  background: `url(${dividerLine}) no-repeat left`,
                                }}
                              />
                              <h5
                                className="text-base"
                                style={{ fontFamily: "Onest-SemiBold" }}
                              >
                                Benefits:
                              </h5>
                              <ul>
                                {tabItem.benefits.map((benefit) => (
                                  <li
                                    className="mt-4 flex items-center text-sm"
                                    key={`${tabItem.title}-${benefit}`}
                                  >
                                    <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                    <span>{benefit}</span>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : (
                            ""
                          )}
                          {tabItem.items ? (
                            <ul>
                              {tabItem.items.map((subItem) => (
                                <li
                                  className="mt-4 flex items-center text-sm"
                                  key={`${tabItem.title}-${subItem}`}
                                >
                                  <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                  <span>{subItem}</span>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                          {tabItem.useCases?.length ? (
                            <>
                              <h5
                                className="text-base mt-8"
                                style={{ fontFamily: "Onest-SemiBold" }}
                              >
                                Use cases:
                              </h5>
                              {tabItem.useCaseDescription ? (
                                <p className="my-4 text-sm text-white100">
                                  {tabItem.useCaseDescription}
                                </p>
                              ) : (
                                ""
                              )}
                              <ul>
                                {tabItem.benefits.map((benefit) => (
                                  <li
                                    className="mt-4 flex items-center text-sm"
                                    key={`${tabItem.title}-${benefit}`}
                                  >
                                    <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                    <span>{benefit}</span>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : (
                            ""
                          )}
                          {tabItem.extraSubservice ? (
                            <div>
                              <div
                                className="h-[1px] my-8"
                                style={{
                                  background: `url(${dividerLine}) no-repeat left`,
                                }}
                              />
                              <h4 className="text-2xl">
                                {tabItem.extraSubservice.title}
                              </h4>
                              {tabItem.extraSubservice.description.map(
                                (descr: string) => (
                                  <p
                                    className="mt-4 text-base text-white55"
                                    key={descr}
                                  >
                                    {descr}
                                  </p>
                                )
                              )}
                              {tabItem.items ? (
                                <ul>
                                  {tabItem.extraSubservice.items.map(
                                    (subItem) => (
                                      <li
                                        className="mt-4 flex items-center text-sm"
                                        key={`${tabItem.title}-${subItem}`}
                                      >
                                        <ChevronRightDouble className="mr-3 min-w-[24px]" />
                                        <span>{subItem}</span>
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </div>
                  </section>
                </div>
              </div>

              {serviceDetails.additionalContent?.length ? (
                <div className="max-w-[1120px] mx-auto pt-[120px] md:pt-[150px]">
                  <section className="mx-4 md:mx-6 xl:mx-0">
                    {serviceDetails.additionalContent.map((item, index) => (
                      <div
                        className={`flex flex-col md:flex-row gap-6 ${
                          index !== serviceDetails.additionalContent.length - 1
                            ? "border-b border-dashed border-white15 pb-10"
                            : ""
                        } ${index > 0 ? "pt-10" : ""}`}
                        key={item.title}
                      >
                        <div className="text-lg text-white100 flex-[3]">
                          {item.title}
                        </div>
                        <div className="flex-[5] flex flex-col gap-8">
                          {item.description.map((descrItem) => (
                            <p
                              key={descrItem}
                              className="py-0 text-white55 text-base"
                            >
                              {descrItem}
                            </p>
                          ))}
                        </div>
                      </div>
                    ))}
                  </section>
                </div>
              ) : (
                ""
              )}

              <div className="max-w-[1120px] mx-auto">
                <section className="mx-4 md:mx-6 xl:mx-0">
                  <div className="mt-[120px] md:mt-[150px] border-t border-dashed border-white15 mx-4 md:mx-6 xl:mx-0" />
                  <BookCallWith
                    className="mt-[120px] md:mt-[150px]"
                    imgUrl={serviceDetails.contact.image}
                    name={serviceDetails.contact.name}
                    lnUrl={serviceDetails.contact.lnUrl}
                    calendlyCallPostfix={
                      serviceDetails.contact.calendlyCallPostfix
                    }
                    text={serviceDetails.contact.text || undefined}
                  />
                </section>
              </div>
            </div>
          )
        ) : (
          <div className="max-w-[1120px] mx-auto pt-[80px]">
            <h1 className="pt-[100px] md:pt-[120px] text-white100 text-5xl">
              No service found
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceDetails;
